* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;

  font-family: "open_sansbold", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: var(--white); */
}

:root {
  --blue: #00c4ff;
  --navy: #0a2852;
  --salmon: #ff5c66;
  --white: #fbfdfb;
  --purple: #5e3d82;
  --yellow: #ffff28;
  --speed: 500ms;
}

/* FONTS */

@font-face {
  font-family: "opensans_lightitalic";
  src: url(./fonts/OpenSans-LightItalic.ttf);
  font-style: normal;
}

@font-face {
  font-family: "opensans_bold";
  src: url(./fonts/OpenSans-Bold.ttf);
  font-style: normal;
}

@font-face {
  font-family: "opensans_extrabold";
  src: url(./fonts/OpenSans-ExtraBold.ttf);
  font-style: normal;
}

@font-face {
  font-family: "bitter_italic";
  src: url(./fonts/Bitter-Italic.ttf);
  font-style: normal;
}

@font-face {
  font-family: "bitter_regular";
  src: url(./fonts/Bitter-Regular.ttf);
  font-style: normal;
}

@font-face {
  font-family: "bitter_bold";
  src: url(./fonts/Bitter-Bold.ttf);
  font-style: normal;
}

@font-face {
  font-family: "din";
  src: url(./fonts/DIN\ Alternate\ Bold.ttf);
  font-style: normal;
}

@font-face {
  font-family: "din_condensed";
  src: url(./fonts/DIN\ Condensed\ Bold.ttf);
  font-style: normal;
}

@font-face {
  font-family: "barlowcondensed_semibold";
  src: url(./fonts/BarlowCondensed-SemiBold.ttf);
  font-style: normal;
}

/* OVERRIDE DEFAULT STYLES */

ul {
  list-style: none;
  margin: 0;
  padding: 0;
}

a {
  color: var(--navy);
  text-decoration: none;
}

a:hover {
  color: var(--blue);
  cursor: pointer;
  text-decoration: underline;
}

/* NAVBAR */

.Items {
  background-color: var(--white);
  height: 14vh;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1.2 rem;
  position: -webkit-sticky;
  position: sticky;
  top: 0;
  z-index: 10000;
}

.swingleft-logo {
  size: 1em;
  justify-self: start;
  margin-left: 0px;
  cursor: pointer;
}

.nav-menu {
  display: grid;
  grid-template-columns: repeat(5, auto);
  gap: 25px;
  list-style: none;
  text-align: center;
  align-items: center;
  width: 70vw;
  justify-content: end;
  margin-right: 1rem;
}

.nav-links {
  color: var(--navy);
  text-decoration: none;
  padding: 0.5rem 1rem;
  font-size: 1.7rem;
}

.nav-links:hover {
  text-decoration: underline;
  transition: all var(--speed) ease-in;
}

.social-icons {
  display: flex;
}

/* CARET LINKS */

.nav-links-caret {
  color: var(--navy);
  text-decoration: none;
  padding: 0.5rem 0.3rem 0.5rem 1rem;
  font-size: 1.7rem;
}

.nav-links-caret:after {
  content: "";
  display: block;
  background-image: url("./components/navbar/caret-icon.svg");
  background-size: 22px 22px;
  width: 22px;
  height: 22px;
  float: right;
  margin: 8px 6px 0 0;
}

.nav-links-caret:hover {
  text-decoration: underline;
}

/* BURGER MENU */

.burger {
  display: none;
}

/* DROPDOWN MENU */

.dropdown {
  width: 300px;
  position: absolute;
  top: 80px;
  list-style: none;
  text-align: start;
  transition: all var(--speed) ease-in;
  box-shadow: 0px 3px 15px rgba(0, 0, 0, 0.2);
}

.dropdown li {
  background-color: var(--white);
  cursor: pointer;
}

.dropdown.clicked {
  display: none;
}

.dropdown-link {
  text-decoration: none;
  display: block;
  width: 100%;
  height: 100%;
  color: var(--navy);
  padding: 16px;
}

.dropdown-link:hover {
  text-decoration: underline;
}

/* HOMEPAGE */

/* .hero {
  background-size: cover;
  width: 100vw;
} */

.homepage {
  width: 100%;
  height: 95vh;
  background-image: url(./photos/nyc.jpg);
  background-size: cover;
  background-position: top;
}

.home-text {
  font-family: "barlowcondensed_semibold";
  color: var(--white);
  background-color: var(--navy);
  font-size: 2rem;
  /* padding-left: 5%; */
  padding-left: 5%;
  padding-right: 5%;
  text-align: center;
}

#dragcon {
  height: 700px;
}

#groupPhoto {
  height: 700px;
}

#home {
  width: 100%;
}

/* BUTTON */

.btn {
  padding: 8px 20px;
  border-radius: 100px;
  outline: none;
  border: none;
  cursor: pointer;
  color: var(--white);
  height: 60px;
}

.btn:hover {
  background-color: var(--navy);
  transition: all var(--speed) ease-out;
}

.btn--blue {
  background-color: var(--blue);
}

.btn--salmon {
  background-color: var(--salmon);
}

.btn--navy {
  background-color: var(--navy);
}

.btn--navy:hover {
  background-color: var(--salmon);
  color: var(--navy);
}

.btn--small {
  position: absolute;
  top: 0;
  right: 0;
  font-size: 0.7rem;
  height: 25px;
  border-radius: 1px;
  padding: 6px;
  margin: 10px;
}

.btn--medium {
  font-size: 1.5rem;
}

.btn--large {
  padding: 12px 26px;
  font-size: 1.8rem;
}

/* "GET INVOLVED" MODAL */

.modal-title {
  font-family: "barlowcondensed_semibold";
  font-size: 4rem;
  margin: 10px;
  color: var(--blue);
}

.modal-h2 {
  font-family: "bitter_italic";
  font-size: 1.4rem;
  color: var(--navy);
}

.modal-item {
  font-size: 1.4rem;
  margin: 10px;
  color: var(--navy);
  font-family: "barlowcondensed_semibold";
  text-align: center;
}

.modal-item:hover {
  color: var(--blue);
  text-decoration: underline;
}

.modal {
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  top: 45%;
  left: 50%;
  transform: translate(-50%, -75%);
  padding: 20px;
  padding-right: 2px;
  z-index: 20000;
  border-radius: 2px;
  width: 55vw;
  background-color: var(--white);
}

.row {
  display: flex;
  flex-direction: row;
  margin-bottom: 10px;
}

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 20000;
}

/* MEDIA QUERY */

@media screen and (max-width: 1100px) {
  .Items {
    position: relative;
    height: 12vh;
    z-index: 10000;
  }

  .nav-menu {
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 500px;
    position: absolute;
    top: 10vh;
    left: -100%;
    opacity: 1;
    transition: all var(--speed) ease;
  }

  .nav-menu.active {
    background: var(--white);
    left: 0;
    opacity: 1;
    transition: all var(--speed) ease;
    z-index: 1;
  }

  .nav-links {
    text-align: left;
    padding: 0.5rem;
    width: 100%;
    display: table;
  }

  .nav-links:hover {
    text-decoration: underline;
  }

  .nav-links-caret {
    text-align: left;
    padding: 0.5rem;
    width: 100%;
    display: table;
    margin-left: 2vw;
  }

  .nav-links-caret:after {
    content: "";
    display: block;
    background-image: url("./components/navbar/caret-icon.svg");
    background-size: 22px 22px;
    width: 22px;
    height: 22px;
    float: right;
    margin: 8px 6px 0 0;
  }

  .nav-links-caret:hover {
    text-decoration: underline;
  }

  .swingleft-logo {
    position: absolute;
    top: 0;
    left: 0;
    transform: translate(5%, 50%);
    height: 46px;
    width: 144px;
  }

  .burger {
    position: absolute;
    top: 0;
    right: 0;
    z-index: 1;
    width: 60px;
    height: 50px;
    padding: 1rem;
    margin-right: 1rem;
    margin-top: 1.7rem;
    background: var(--white);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }

  .burger .open {
    position: relative;
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background-color: var(--navy);
    display: flex;
    align-items: center;
    justify-content: center;
    transition: all var(--speed) ease-in;
  }

  .burger .open:before,
  .burger .open:after {
    content: "";
    position: absolute;
    z-index: 1;
    top: -10px;
    width: 100%;
    height: 4px;
    background: inherit;
    border-radius: inherit;
  }

  .burger .open:after {
    top: 9px;
  }

  .burger .x {
    position: relative;
    width: 100%;
    height: 4px;
    border-radius: 2px;
    background-color: var(--navy);
    display: flex;
    align-items: center;
    justify-content: center;
    transform: rotate(135deg);
    transition: all var(--speed) ease-in;
  }

  .burger .x:after {
    content: "";
    position: absolute;
    z-index: 1;
    width: 100%;
    height: 4px;
    background: inherit;
    border-radius: inherit;
    transform: rotate(90deg);
    transition: all var(--speed) ease-in;
  }

  .modal {
    width: 80vw;
    top: 65%;
  }
}

/* REP FORM */

#voter-form {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-content: space-around;
  border: 1px solid #0a2852;
  width: 70vw;
  height: 40vh;
  border-radius: 10px;
  align-items: baseline;
  margin: 3%;
  padding: 3%;
}

#form-container {
  display: flex;
  flex-flow: column wrap;
  align-items: center;
}

.break {
  flex-basis: 90%;
  height: 0;
}

/* CONTACT */

#contact-header {
  font-family: "barlowcondensed_semibold";
  color: var(--navy);
  text-align: center;
  font-size: 2rem;
}

#contact-form {
  display: flex;
  flex-flow: row wrap;
  justify-content: space-evenly;
  align-content: space-around;
  border: 1px solid #0a2852;
  width: 80vw;
  height: 70vh;
  border-radius: 10px;
  align-items: baseline;
  margin: 3%;
  padding: 3%;
}
#form-container {
  margin: 10px;
  display: flex;
  align-content: center;
}

#form-header {
  text-align: center;
  font-family: "barlowcondensed_semibold";
  color: #0a2852;
}

.email-link {
  font-family: "barlowcondensed_semibold";
  font-size: 2rem;
}

.post-container {
  display: flex;
  align-items: center;
  align-content: center;
  max-width: 85%;
  margin: auto;
  padding-top: 15px;
  padding-bottom: 15px;
  height: 100%;
}

.news-container {
  display: flex;
  flex-direction: column;
  margin: auto;
  background-color: #316ce1;
  font-family: "barlowcondensed_semibold";
  padding-top: 30px;
}
